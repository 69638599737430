import React, {
  FormEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../../../providers/AuthProvider";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import Text from "../../../components/Typography/Text";
import { BulkMutation } from "../../../plugins/middleware-api-client";
import { useCSVReader } from "react-papaparse";

interface StockUploadProps {
  open: boolean;
  closeModal: () => void;
}

const StockUploadModal: React.FC<StockUploadProps> = (
  props: StockUploadProps
) => {
  const authContext = useContext(AuthContext);

  const [fromStoreId, setFromStore] = useState<string>("");
  const [toStoreId, setToStore] = useState<string>(""); // authcontext.stores[0].id

  const [buttonLook, setButtonLook] = useState<string>(ButtonStyle.NEW);
  const [buttonText, setButtonText] = useState<string | undefined>(undefined);
  const [csvFile, setCsvFile] = useState([]);
  const { CSVReader } = useCSVReader();
  const [sameStoreIsSelected, setSameStore] = useState<boolean>(false);

 

  // stores = authContext.stores
  // add a handleSubmit that handles exporting the products
  // this should submit a stockmutation for the store from which the product(s) are written off
  // it should of course also handle the submit to add the stockmutations for the store to which the products are exported
  const handleSubmit = async (mutations: BulkMutation[]) => {
    console.log(mutations);
    const res = await authContext.bulkMutations(mutations);
    if (res) {
      props.closeModal();
    }
  };

  const handleCsvFile = (csvFile: [], file: string) => {
    setCsvFile(csvFile);
    try {
      updateButton(ButtonStyle.SAVE, file);
    } catch (e) {
      updateButton(ButtonStyle.ERROR, "Fout gevonden in een EAN code");
    }
  };

  const convertCsvFileToMutations = () => {
    let mutations = csvFile.map((row) => {
      return {
        eanCode: parseInt(row["EAN code"]),
        quantity: parseInt(row["Voorraad mutatie"]),
        storeId: toStoreId,
        note: "",
      };
    });
    // add extra minus mutations if the stock is exported from a certain store
    if (
      fromStoreId !== undefined &&
      fromStoreId !== "" &&
      fromStoreId !== toStoreId
    ) {
      mutations = mutations.concat(
        csvFile.map((row) => {
          return {
            eanCode: parseInt(row["EAN code"]),
            quantity: -1 * parseInt(row["Voorraad mutatie"]),
            storeId: fromStoreId,
            note: "Voorraad verplaatst naar andere winkel",
          };
        })
      );
    }
    return mutations;
  };

  const updateButton = (style: ButtonStyle, buttonText: string) => {
    setButtonLook(style);
    setButtonText(buttonText);
  };

  useEffect(() => {
    setSameStore(fromStoreId === toStoreId);
  }, [fromStoreId, toStoreId]); 

  useEffect(() => {
    if (authContext.stores[0]) {
      setToStore(authContext.stores[0].id);
    }
  }, [authContext.stores]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => props.closeModal()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 md:w-1/2">
              <form
                onSubmit={async (e: FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  let mutations = convertCsvFileToMutations(); // this should set the mutations
                  handleSubmit(mutations);
                }}
                className="space-y-8"
              >
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-secondary"
                    >
                      Upload Voorraad
                    </Dialog.Title>
                    <div className="mt-2">
                      <Text classList="text-sm">
                        {/* Text to display under the header in the modal can go here */}
                      </Text>
                    </div>
                  </div>
                  <label
                    htmlFor="uploadButton"
                    className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2 after:content-['*'] after:text-red-500"
                  >
                    Voorraad Bestand
                  </label>
                  <CSVReader
                    onUploadAccepted={(results: any, file: File) => {
                      if (
                        !results.data[0].hasOwnProperty("EAN code") ||
                        !results.data[0].hasOwnProperty("Voorraad mutatie")
                      ) {
                        setButtonLook(ButtonStyle.ERROR);
                        return;
                      }
                      updateButton(ButtonStyle.CANCEL, "");
                      handleCsvFile(results.data, file.name);
                    }}
                    config={{
                      header: true,
                    }}
                  >
                    {({ getRootProps, acceptedFile }: any) => (
                      <>
                        <Button
                          type="button"
                          buttonStyle={buttonLook}
                          {...getRootProps()}
                          id="uploadButton"
                        >
                          {(buttonText && buttonText) ||
                            (acceptedFile &&
                              acceptedFile.name.substring(0, 10)) ||
                            "Upload"}
                        </Button>
                      </>
                    )}
                  </CSVReader>
                  <div
                    style={{ maxHeight: "60vh" }}
                    className="overflow-y-scroll"
                  >
                    <label
                      htmlFor="fromStore"
                      className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2"
                    >
                      Van Winkel
                    </label>
                    <span className="text-xs italic">
                      Alleen gebruiken bij overhevelen van voorraad
                    </span>
                    <select
                      id="fromStore"
                      name="fromStore"
                      className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => {
                        setFromStore(e.target.value);
                      }}
                    >
                      <option key="" value="">
                        Geen
                      </option>
                      {authContext.stores.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor="toStore"
                      className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2 after:content-['*'] after:text-red-500"
                    >
                      Naar Winkel
                    </label>
                    <select
                      id="toStore"
                      name="toStore"
                      className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => {
                        setToStore(e.target.value);
                      }}
                    >
                      {authContext.stores.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-between mt-5 sm:mt-6 sticky bottom-0 bg-white">
                  <Button
                    type="button"
                    buttonStyle={ButtonStyle.CANCEL}
                    onClick={() => {
                      setFromStore("");
                      setToStore(authContext.stores[0].id);
                      props.closeModal();
                    }}
                  >
                    Annuleren
                  </Button>
                  <Button
                    disabled={sameStoreIsSelected || csvFile.length === 0}
                    buttonStyle={
                      sameStoreIsSelected || csvFile.length === 0
                        ? ButtonStyle.ERROR
                        : ButtonStyle.SAVE
                    }
                  >
                    Toevoegen
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default StockUploadModal;
