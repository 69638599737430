import React from "react";
import {classNames} from "../../utils/classNames";

export enum ButtonStyle {
  CANCEL = "bg-white hover:bg-gray-50 text-gray-700 border-gray-300",
  SAVE = "bg-secondary-100 hover:bg-secondary-200 border-transparent text-white focus:ring-secondary-100",
  NEW = "bg-white hover:bg-green-50 border-secondary text-secondary",
  ERROR = "text-red-500 hover:text-red-800 border-red-500 hover:border-red-800 hover:bg-gray-50",
  WARNING = "text-yellow-500 hover:text-yellow-800 border-yellow-500 hover:border-yellow-800 hover:bg-yellow-50"
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  classList?: string
  buttonStyle?: ButtonStyle
}

const Button:React.FC<ButtonProps> = ({classList, buttonStyle, children, ...rest}) => {
  if(!classList) classList = ""
  if(!buttonStyle) buttonStyle = ButtonStyle.SAVE
  return (
    <button
      {...rest}
      className={classNames(
        "my-2 py-2 px-4 border rounded-md shadow-sm text-sm font-normal focus:outline-none focus:ring-2 focus:ring-offset-2",
        classList, buttonStyle,
      )}
    >
      {children}
    </button>
  )
}

export default Button;
