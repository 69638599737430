import React from "react";
import {Supplier} from "../../../plugins/middleware-api-client";
import Text from "../../../components/Typography/Text";
import Heading, {Level} from "../../../components/Typography/Heading";
import {TextColor} from "../../../components/Typography/TextStyles";

interface SupplierListProps {
  suppliers: Supplier[] | undefined
}

const SupplierList: React.FC<SupplierListProps> = ({suppliers}) => {
  return (
    <div className="mt-8 md:mt-12">
      <Heading tag={"h2"} level={Level.HEADING_5} classList="mb-4">Leveranciers</Heading>
      {suppliers &&
        <div className="mt-1 grid grid-cols-1 md:grid-cols-2 gap-4">
          {suppliers.map((supplier) => (
            <div
              className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm grid grid-cols-12"
              key={supplier.name}
            >
              <div
                className="relative flex items-center space-x-3 col-span-12"
              >
                <div className="flex-shrink-0">
                  {supplier.image !== "" ?
                    <img className="h-10 w-10 rounded-full" src={supplier.image} alt=""/>
                    : <svg className="h-10 w-10 rounded-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                    </svg>}
                </div>
                <div className="flex-1 min-w-0">
                  <Text classList="font-semibold">{supplier.name}</Text>
                  <Text textColor={TextColor.PRIMARY} classList="text-sm">{supplier.street_name} {supplier.street_number}</Text>
                  <Text textColor={TextColor.PRIMARY} classList="text-sm">{supplier.city}</Text>
                </div>
              </div>
              <Text classList="col-span-12 md:col-span-10 mt-4">{supplier.location_name}</Text>
              <Text classList="col-span-12 md:col-span-10 mt-4">{supplier.description}</Text>
              {supplier.usps.map((usp, index) => (
                <Text key={`usp-${index}`} textColor={TextColor.PRIMARY} classList="col-span-12 md:col-span-10 mt-4 text-sm">
                  {usp}
                </Text>
              ))}
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default SupplierList;