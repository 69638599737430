import React from "react";
import Modal from "./Modal";
import Button, {ButtonStyle} from "../Button/Button";

interface ConfirmModalProps {
  cancelAction: React.Dispatch<React.SetStateAction<boolean>>
  confirmAction: () => Promise<void>
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayText?: string  
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({isOpen, setIsOpen, confirmAction, cancelAction, displayText="Weet je zeker dat je dit product wil verwijderen?"}) => {
  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Modal.Body>
          <div className="mb-4">
            <h3 className="text-brand-gray-500 font-bold">
            {displayText}
            </h3>
          </div>

          <div className="w-full flex flex-col md:flex-row md:justify-between">
            <Button
              type="button"
              buttonStyle={ButtonStyle.CANCEL}
              onClick={() => cancelAction(false)}
            >
              Annuleren
            </Button>

            <Button
              type="button"
              buttonStyle={ButtonStyle.ERROR}
              onClick={() => confirmAction()}
            >
              Bevestig
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmModal