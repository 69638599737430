import React, {useEffect, useState} from "react";
import Input from "../../../components/Form/Input";

interface SupplierUspsFormProps {
  supplierIndex: number;
  uspIndex: number;
  supplierUsp: string;
  updateUsp: (value: string) => void;
  removeUsp: (uspIndex: number) => void;
}

const SupplierUspsForm:React.FC<SupplierUspsFormProps> = ({supplierIndex, uspIndex, supplierUsp, updateUsp, removeUsp}) => {
  const [usp, setUsp] = useState<string>(supplierUsp)
  useEffect(() => {
    setUsp(supplierUsp)
  }, [supplierUsp])
  return (
    <div
      key={`supplier-${supplierIndex}-usp-${uspIndex}`}
      className="flex"
    >
      <Input
        inline={false}
        id={`supplier-${supplierIndex}-usp-${uspIndex}`}
        name={`supplier-${supplierIndex}-usp-${uspIndex}`}
        classList={"flex-grow"}
        type="text"
        defaultValue={usp}
        onChange={(e) => {
          updateUsp(e.target.value)
        }}
      />
      <button
        className="text-primary hover:primary-600 w-8"
        type="button"
        onClick={() => removeUsp(uspIndex)}
      >
        x
      </button>
    </div>
  )
}

export default SupplierUspsForm;