import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useRef} from "react";
import {classNames} from "../../utils/classNames";

export interface IModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maxWidth?: string;
  children?: React.ReactNode;
  enableCloseOnBody?: boolean;
}

interface IModalHeader {
  children: React.ReactNode;
}

interface IModalBody {
  children: React.ReactNode;
}

interface IModalFooter {
  children: React.ReactNode;
}

const Header: React.FC<IModalHeader> = (props) => {
  return (
    <div className="px-12 py-6 border-b border-gray-300">{props.children}</div>
  );
};

const Body: React.FC<IModalBody> = (props) => {
  return (
    <div className="w-full px-6 py-6 overflow-y-auto">
      {props.children}
    </div>
  );
};

const Footer: React.FC<IModalFooter> = (props) => {
  return (
    <div className="mt-4 bg-gray-150 w-full px-8 py-8 flex flex-wrap justify-between">
      {props.children}
    </div>
  );
};

const Modal: React.FC<IModalProps> = (
  {
    isOpen,
    setIsOpen,
    maxWidth,
    children,
    enableCloseOnBody = true
  }: IModalProps) => {
  let hiddenButtonRef = useRef(null);
  if (!maxWidth) maxWidth = "max-w-3xl md:max-w-3xl";

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="h-screen fixed inset-0 z-50 overflow-y-auto p-1 md:p-16  flex items-center justify-center"
          onClose={
            enableCloseOnBody ? setIsOpen : () => {
            }
          }
          initialFocus={hiddenButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-20"/>
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                maxWidth,
                "inline-block my-6 mx-auto text-left overflow-hidden align-middle transition-all transform bg-white rounded-md shadow"
              )}
            >
              {children}

              <button ref={hiddenButtonRef} className="hidden"></button>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default Object.assign(Modal, {
  Header,
  Body,
  Footer,
});
