import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import {Product, StockMutation, StoreProduct} from "../../../plugins/middleware-api-client";
import {getBase64} from "../../../utils/getBase64";
import {CurrencyEuroIcon} from "@heroicons/react/outline";
import Heading, {Level} from "../../../components/Typography/Heading";
import Input from "../../../components/Form/Input";
import {AuthContext} from "../../../providers/AuthProvider";
import TextArea from "../../../components/Form/TextArea";
import MultiInput from "../../../components/Form/MultiInput";
import FileSizeWarningModal, {allowedFileSize} from "../../../components/Modal/FileSizeWarningModal";
import {calculateCostPrice, calculatePriceIncl, calculateProfitMargin} from "../../../utils/product";

interface ProductFormProps {
  handleSubmit: (product: Product, stock: StoreProduct[]) => void
  handleCancel: () => void
  productToUpdate?: Product | null
}

const ProductForm:React.FC<ProductFormProps> = ({handleSubmit, handleCancel, productToUpdate}) => {
  const vatOptions:number[] = [
    0,9,21
  ]
  const categories:string[] = [
    "Alles",
    "Verpakkingsvrij",
    "Schoonmaak & Keuken",
    "Persoonlijke verzorging",
    "Zaden & Planten",
    "Kadootjes",
    "Met een tof verhaal",
  ]
  const authContext = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false)
  const [stock, setStock] = useState<StoreProduct[]>([])
  const [stockLoaded, setStockLoaded] = useState(false)
  const [product, setProduct] = useState<Product>(productToUpdate ?? {
    calculationMethod: "priceIncl",
    category: categories[0],
    supplier: authContext.suppliers ? (authContext.suppliers[0].id ?? "") : "",
    description: "",
    tags: [],
    id: "",
    image: "",
    isVisible: true,
    isWeight: false,
    priceIncl: 0,
    vat: 9,
    margin: 0,
    deposit: 0,
    title: "",
    unitOfMeasurement: "",
    biologic: false,
    brand: "",
    costPrice: 0,
    minimalOrderQuantity: 0,
    eanCode: 0
  });
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileSizeWarningModal, setFileSizeWarningModal] = useState<boolean>(false);

  useEffect(() => {
    if(productToUpdate) {
      setProduct(productToUpdate)
    }
    setLoaded(true)
    getProductStock()
    authContext.fetchSuppliers();
    // authContext.fetchCategories();
  }, [])

  const canEditCostPrice = product.calculationMethod === "priceIncl";
  const canEditPriceIncl = product.calculationMethod === "costPrice";
  const canEditMargin = product.calculationMethod === "profitMargin";

  useEffect(() => {
    if (!product.supplier && authContext.suppliers && authContext.suppliers.length > 0) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        supplier: authContext.suppliers[0].id,
      }));
    }
    if (!product.calculationMethod) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        calculationMethod: "priceIncl",
      }));
    }
  }, [product])

  useEffect(() => {
    if (canEditCostPrice) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        priceIncl: calculatePriceIncl(prevProduct),
      }));
    } else if (canEditPriceIncl) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        costPrice: calculateCostPrice(prevProduct),
      }));
    } else if (canEditMargin) {
      setProduct((prevProduct) => {
        return {
          ...prevProduct,
          margin: calculateProfitMargin(prevProduct)
        }
      });
    }
  }, [canEditPriceIncl, canEditCostPrice, canEditMargin, product.costPrice, product.priceIncl, product.margin, product.vat]);

  const getProductStock = async () => {
    if(productToUpdate) {
      const res = await authContext.fetchProductStock(productToUpdate.id)
      setStock(res)
    }
    setStockLoaded(true)
  }

  const updateImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    let file = e.target.files[0]
    if (!allowedFileSize(file.size)) {
      setFileSize(file.size)
      setFileSizeWarningModal(true)
      return false;
    }
    let base64Image = await getBase64(file)
    setProduct((f) => ({
      ...f,
      image: base64Image
    }))
  }

  return (
    <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(product, stock)
    }} className="space-y-8">
      {loaded&&
        <div className="space-y-8 sm:space-y-5">
          <div>
            <div>
              { productToUpdate ?
                <Heading level={Level.HEADING_2}>Product aanpassen</Heading>
                :
                <Heading level={Level.HEADING_2}>Product aanmaken</Heading>
              }
            </div>
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="photo" className="block text-sm font-medium text-secondary">
                  Afbeelding
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                      <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100 flex">
                        {product.image &&
                        <img className="self-center" src={product.image} alt="Product"/>
                        }
                        {!product.image &&
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                        </svg>
                        }
                      </span>
                    <label
                      htmlFor="file-upload"
                      className="ml-5 relative cursor-pointer rounded-md font-medium text-secondary-600 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
                    >
                      <span>Afbeelding uploaden</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        accept="image/*"
                        onChange={updateImage}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <Input
                label="Titel"
                id="product-title"
                name="product-title"
                type="text"
                defaultValue={product.title}
                onChange={(e) => {
                  setProduct((f) => ({
                    ...f,
                    title: e.target.value
                  }))
                }}
              />

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="price" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                        Berekenen
                    </label>
                    <div className="mt-1 relative rounded-md">
                        <input
                            className="mr-2"
                            type="radio"
                            name="calculationMethod"
                            id="calculationMethod-costPrice"
                            value="costPrice"
                            defaultChecked={product.calculationMethod === "costPrice"}
                            aria-describedby="price-currency"
                            onChange={(e) => {
                              setProduct((f) => ({
                                ...f,
                                calculationMethod: "costPrice"
                              }))
                            }}
                        />
                        <label htmlFor="calculationMethod-costPrice" className="mr-8">Inkoop</label>
                        <input
                            className={"mr-2"}
                            type="radio"
                            name="calculationMethod"
                            id="calculationMethod-priceIncl"
                            value="priceIncl"
                            defaultChecked={product.calculationMethod === "priceIncl"}
                            aria-describedby="price-currency"
                            onChange={(e) => {
                              setProduct((f) => ({
                                ...f,
                                calculationMethod: "priceIncl"
                              }))
                            }}
                        />
                        <label htmlFor="calculationMethod-priceIncl" className="mr-8">Verkoop</label>
                        <div className="mt-2 relative rounded-md">
                          <input
                              className={"mr-2"}
                              type="radio"
                              name="calculationMethod"
                              id="calculationMethod-margin"
                              value="margin"
                              defaultChecked={product.calculationMethod === "profitMargin"}
                              aria-describedby="price-currency"
                              onChange={(e) => {
                                setProduct((f) => ({
                                  ...f,
                                  calculationMethod: "profitMargin"
                                }))
                              }}
                          />
                          <label htmlFor="calculationMethod-priceIncl" className="mr-8">Winstmarge</label>
                        </div>
                    </div>
                </div>



              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="price" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                  Inkoopprijs, excl.
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <CurrencyEuroIcon className="w-5 mr-1 inline-block text-gray-500"/>
                  </div>
                  <input
                      type="number"
                      name="costPrice"
                      id="costPrice"
                      step="any"
                      className={`focus:ring-secondary focus:border-secondary block w-full pl-8 pr-12 sm:text-sm ${canEditPriceIncl ? 'border-gray-100 bg-gray-100' : 'border-gray-300'}  rounded-md`}
                      value={(product.costPrice / 100)}
                      disabled={!canEditCostPrice && !canEditMargin}
                      aria-describedby="price-currency"
                      onChange={(e) => {
                        setProduct((f) => ({
                          ...f,
                          costPrice: Math.round(parseFloat(e.target.value) * 100)
                        }))
                      }}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      EUR
                    </span>
                  </div>
                </div>
              </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="price" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                        Verkoopprijs, incl.
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <CurrencyEuroIcon className="w-5 mr-1 inline-block text-gray-500"/>
                        </div>
                        <input
                            type="number"
                            name="price"
                            id="price"
                            step="any"
                            className={`focus:ring-secondary focus:border-secondary block w-full pl-8 pr-12 sm:text-sm ${canEditCostPrice ? 'border-gray-100 bg-gray-100' : 'border-gray-300'} rounded-md`}
                            value={(product.priceIncl / 100)}
                            aria-describedby="price-currency"
                            disabled={!canEditPriceIncl && !canEditMargin}
                            onChange={(e) => {
                              setProduct((f) => ({
                                ...f,
                                priceIncl: Math.round(parseFloat(e.target.value) * 100)
                              }))
                            }}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      EUR
                    </span>
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="vat" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                        Winstmarge %
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="number"
                            id="margin"
                            name="margin"
                            className={`focus:ring-secondary focus:border-secondary block w-full pr-12 sm:text-sm ${canEditMargin ? 'border-gray-100 bg-gray-100' : 'border-gray-300'} rounded-md`}
                            disabled={canEditMargin}
                            value={product.margin}
                            onChange={(e) => {
                              setProduct(f => ({
                                ...f,
                                margin: parseInt(e.target.value)
                              }))
                            }}
                            defaultValue={product.margin ?? 0}
                        >
                        </input>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm" id="price-currency">
                            %
                          </span>
                        </div>
                    </div>
                </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="vat" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                      BTW tarief
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                          id="vat"
                          name="vat"
                          className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => {
                            setProduct(f => ({
                              ...f,
                              vat: parseInt(e.target.value)
                            }))
                          }}
                          defaultValue={product.vat ?? 9}
                      >
                        {vatOptions.map((vat, i) => (
                          <option
                            key={`category_${i}`}
                            value={vat}
                            // selected={category === product.category}
                          >{vat} %</option>
                        ))}
                      </select>
                  </div>
              </div>


              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="price" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                      Statiegeld
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <CurrencyEuroIcon className="w-5 mr-1 inline-block text-gray-500"/>
                      </div>
                      <input
                          type="number"
                          name="deposit"
                          id="deposit"
                          step="any"
                          className="focus:ring-secondary focus:border-secondary block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md"
                          defaultValue={(product.deposit / 100)}
                          aria-describedby="price-currency"
                          onChange={(e) => {
                            setProduct((f) => ({
                              ...f,
                              deposit: Math.round(parseFloat(e.target.value) * 100)
                            }))
                          }}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    EUR
                  </span>
                      </div>
                  </div>
              </div>
              <TextArea
                label="Beschrijving"
                id="description"
                name="description"
                type="text"
                defaultValue={product.description}
                onChange={(e) => {
                  setProduct((f) => ({
                    ...f,
                    description: e.target.value
                  }))
                }}
              />
              <MultiInput
                values={product.tags}
                label="Tags"
                id="tags"
                name="tags"
                type="text"
                updateValues={(values) => {
                  setProduct((f) => ({
                    ...f,
                    tags: values
                  }))
                }}
              />

              <Input
                label="Meet eenheid"
                id="unitOfMeasurement"
                name="unitOfMeasurement"
                type="text"
                defaultValue={product.unitOfMeasurement}
                onChange={(e) => {
                  setProduct((f) => ({
                    ...f,
                    unitOfMeasurement: e.target.value
                  }))
                }}
              />
              <Input
                label="Is gewicht"
                id="is-weight"
                name="is-weight"
                type="checkbox"
                defaultChecked={product.isWeight}
                onChange={(e) => {
                  setProduct((f) => ({
                    ...f,
                    isWeight: e.target.checked
                  }))
                }}
                required={false}
              />
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="country" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                  Categorie
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="category"
                    name="category"
                    className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => {
                      setProduct(f => ({
                        ...f,
                        category: e.target.value
                      }))
                    }}
                    defaultValue={product.category}
                  >
                    {categories.map((category, i) => (
                      <option
                        key={`category_${i}`}
                        value={category}
                        // selected={category === product.category}
                      >{category}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="supplier" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                  Leverancier
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="supplier"
                    name="supplier"
                    autoComplete="country-name"
                    className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => {
                      setProduct(f => ({
                        ...f,
                        supplier: e.target.value
                      }))
                    }}
                    value={product.supplier}
                  >

                    {authContext.suppliers.map(supplier => (
                      <option
                        key={supplier.id}
                        value={supplier.id}
                        // selected={supplier.id === product.supplier}
                      >{supplier.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <Input
                label="Actief"
                id="is-visible"
                name="is-visible"
                type="checkbox"
                defaultChecked={product.isVisible}
                onChange={(e) => {
                  setProduct((f) => ({
                    ...f,
                    isVisible: e.target.checked
                  }))
                }}
                required={false}
              />
              <Input
                  label="Biologisch"
                  id="biologic"
                  name="biologic"
                  type="checkbox"
                  defaultChecked={product.biologic}
                  onChange={(e) => {
                    setProduct((f) => ({
                      ...f,
                      biologic: e.target.checked
                    }))
                  }}
                  required={false}
              />
              <Input
                  label="Merk"
                  id="brand"
                  name="brand"
                  type="text"
                  required={false}
                  defaultValue={product.brand}
                  onChange={(e) => {
                    setProduct((f) => ({
                      ...f,
                      brand: e.target.value
                    }))
                  }}
              />
              <Input
                  label="Min. Bestelhoeveelheid"
                  id="minimalOrderQuantity"
                  name="minimalOrderQuantity"
                  type="number"
                  defaultValue={product.minimalOrderQuantity}
                  onChange={(e) => {
                    setProduct((f) => ({
                      ...f,
                      minimalOrderQuantity: parseFloat(e.target.value)
                    }))
                  }}
              />
              {stockLoaded && authContext.stores.map((store, i) => (
                <Input
                    label={`Min. Voorraad ${store.name}`}
                    id="minimalOrderQuantity"
                    name="minimalOrderQuantity"
                    type="number"
                    key={`minimalOrderQuantity_${i}`}
                    defaultValue={stock.find(s => s.storeId === store.id)?.targetQuantity || 0}
                    onChange={(e) => {
                      setStock((f) => {
                        const index = f.findIndex(s => s.storeId === store.id)
                        if (index === -1) {
                          if(productToUpdate) return f
                          return [
                            ...f,
                            {
                              id: '',
                              storeId: store.id,
                              targetQuantity: parseFloat(e.target.value),
                              productId: '',
                              stockMutations: [] as Array<StockMutation>,
                              stockLevel: 0,
                              isVisible: false
                            }
                          ]
                        }

                        return [
                          ...f.slice(0, index),
                          Object.assign({}, f[index], {
                            targetQuantity: parseFloat(e.target.value)
                          }),
                          ...f.slice(index + 1)
                        ]
                      })
                    }}
                />
              ))}
            </div>
          </div>
        </div>
      }
      <div className="fixed bottom-0 left-0 right-0 bg-white py-4 px-6">
      <div className="flex justify-between lg:justify-end">
          <Button type="button" onClick={handleCancel} buttonStyle={ButtonStyle.CANCEL}>
            Annuleren
          </Button>
          <Button classList="ml-3">
            Opslaan
          </Button>
        </div>
      </div>
      <FileSizeWarningModal isOpen={fileSizeWarningModal} setIsOpen={setFileSizeWarningModal} fileSize={fileSize}/>
    </form>
  )
}

export default ProductForm;
