import React from "react";

const Panel:React.FC = ({children}) => {
  return (
    <div className="px-4 lg:px-0">
      <div className="bg-white rounded lg:mx-auto my-8 lg:my-16 max-w-7xl p-4 sm:p-6 lg:p-8 shadow relative">
        {children}
      </div>
    </div>
  )
}

export default Panel;