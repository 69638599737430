/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useContext} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {classNames} from "../../utils/classNames";
import {AuthContext} from "../../providers/AuthProvider";
import {NavLink} from "react-router-dom";

const CompanyDropdown:React.FC = () => {
  const authContext = useContext(AuthContext)
  return (
    <Menu as="div" className="relative inline-block text-left z-[401]">
      { authContext.stores &&
        <div>
          <Menu.Button className="text-sm inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-secondary">
            { authContext.activeStore?.name}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
      }

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            { authContext.stores.map(store => {
              if (store.id === authContext.activeStore?.id) return
              return (
                <Menu.Item key={store.id}>
                  {({ active }) => (
                    <p
                      onClick={() => {
                        authContext.switchActiveStore({
                          activeCompanyId: store.id
                        })
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      {store.name}
                    </p>
                  )}
                </Menu.Item>
              )
            })}
            <Menu.Item>
              <NavLink
                to="/store/create"
                className={classNames(
                  'border-t text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100'
                )}
              >
                Nieuwe winkel aanmaken
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <a
                href="#"
                onClick={() => authContext.logout()}
                className={classNames(
                  'bg-gray-100',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Uitloggen
              </a>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default CompanyDropdown;
