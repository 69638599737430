import React, {Fragment, useContext, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AuthContext} from "../../../providers/AuthProvider";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import Text from "../../../components/Typography/Text";
import {BulkMutation} from "../../../plugins/middleware-api-client";
import {log} from "util";

interface StockMutationProps {
  open: boolean;
  closeStockPopUp: (stockUpdated: boolean) => void;
}

interface LocalProduct extends BulkMutation {
  productId: string;
  stockLevel: number;
}

const StockMutation: React.FC<StockMutationProps> = ({
                                                       open,
                                                       closeStockPopUp,
                                                     }) => {
  const authProvider = useContext(AuthContext);
  const [mutations, setMutations] = useState<BulkMutation[]>([]);
  const [store, setStore] = useState<string>("");
  const [localProducts, setLocalProducts] = useState<LocalProduct[]>([])

  /* Creating a new array of mutations, with the eanCode, quantity and storeId. */
  useEffect(() => {
    let initialMutations: BulkMutation[] = [];
    let localProducts: LocalProduct[] = []

    /* Creating a new array of mutations, with the eanCode, quantity and storeId. */
    authProvider.products.forEach((product) => {
      const storeProduct = authProvider.storeProducts.find((storeProduct) => storeProduct.productId === product.id && storeProduct.storeId === store)
      if (storeProduct) {
        const eanCode = product.eanCode
        const stockLevel = storeProduct.stockLevel ?? 0
        initialMutations.push({eanCode: product.eanCode, quantity: 0, storeId: store});
        localProducts.push({productId: product.id, stockLevel: stockLevel, eanCode: eanCode, storeId: store})
      }
    });

    setMutations(initialMutations);
    setLocalProducts(localProducts);

  }, [authProvider.products, store]);

  /**
   * It takes the mutations array, adds the storeId to each mutation, and then sends the mutations to the server
   */
  const handleSubmit = async () => {
    let mutationsWithStore = mutations.map((mutation) => {
      return {...mutation, storeId: store};
    });
    const res = await authProvider.bulkMutations(mutationsWithStore);
    if (res) {
      closeStockPopUp(true);
    }
  };

  /**
   * It takes an eanCode and a value, and then it finds the mutation with the matching eanCode, and then it sets the
   * quantity of that mutation to the value
   * @param {number | undefined} eanCode - number | undefined
   * @param {string} value - The value of the input field
   */
  const handleMutationChange = (eanCode: number | undefined, value: string) => {
    setMutations((f) => {
      const newMutations = [...f];
      const mutationIndex = newMutations.findIndex((m) => m.eanCode === eanCode);
      newMutations[mutationIndex].quantity = parseInt(value);
      return newMutations;
    });
  }

  const handleLossChange = (eanCode: number | undefined, isLoss: boolean) => {
    setMutations((f) => {
      const newMutations = [...f];
      const mutationIndex = newMutations.findIndex((m) => m.eanCode === eanCode);
      newMutations[mutationIndex].note = isLoss ? "" : "no-loss";
      return newMutations;
    });
  }

  /* Setting the store to the active store. */
  useEffect(() => {
    setStore(authProvider.activeStoreId);
  }, [authProvider.activeStoreId]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => closeStockPopUp(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 md:w-1/2">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-secondary"
                  >
                    Voorraad toevoegen
                  </Dialog.Title>
                  <div className="mt-2">
                    <Text classList="text-sm">
                      Vul hier in met hoeveel de voorraad verhoog (of verlaagd)
                      moet worden.
                    </Text>
                  </div>
                </div>
                <div
                  style={{maxHeight: "60vh"}}
                  className="overflow-y-scroll"
                >
                  {localProducts.map((localProduct, index) => {
                    let filteredProduct = authProvider.products.filter((p) => p.eanCode === localProduct.eanCode)[0]
                    let filteredMutation = mutations.filter((m) => m.eanCode === localProduct.eanCode)[0]
                    let filteredMutationQuantity = filteredMutation.quantity ? filteredMutation.quantity : 0
                    if(!filteredProduct) return (<></>)
                    return (
                      <div key={`stock-mutation-${localProduct.eanCode}-${localProduct.storeId}`} className="pt-4">
                        <div>
                          {filteredProduct.title}{" "}
                          <span className={"text-sm font-light"}>
                            ({localProduct.eanCode})
                          </span>
                        </div>
                        <div className="flex gap-4">
                          <div>
                            <label htmlFor={`stock-mutation-${localProduct.eanCode}`} className="font-light text-sm">
                              Actuele voorraad: {localProduct.stockLevel}
                            </label>
                            <input
                              className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                              name={`stock-mutation-${localProduct.eanCode}`}
                              id={`stock-mutation-${localProduct.eanCode}`}
                              type="number"
                              defaultValue={filteredMutation.quantity}
                              onChange={(e) => handleMutationChange(localProduct.eanCode, e.target.value)}
                            />
                          </div>
                          {filteredMutationQuantity < 0 &&
                            <div>
                              <label htmlFor="stock-mutation-loss" className="font-light text-sm">
                                Derving
                              </label>
                              <input
                                className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                                name="stock-mutation-loss"
                                id="stock-mutation-loss"
                                type="checkbox"
                                defaultChecked={true}
                                onChange={(e) => handleLossChange(localProduct.eanCode, e.target.checked)}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    )
                  })}
                  <div className="pt-4">
                    <label htmlFor="toStore" className="pt-4">
                      Winkel
                    </label>
                    <select
                      id="toStore"
                      name="toStore"
                      className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => {
                        setStore(e.target.value);
                      }}
                      value={store}
                    >
                      {authProvider.stores.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-5 sm:mt-6 sticky bottom-0 bg-white">
                <Button
                  type="button"
                  buttonStyle={ButtonStyle.CANCEL}
                  onClick={() => closeStockPopUp(false)}
                >
                  Annuleren
                </Button>
                <Button
                  type="button"
                  buttonStyle={ButtonStyle.SAVE}
                  onClick={handleSubmit}
                >
                  Toevoegen
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default StockMutation;
