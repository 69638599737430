import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import Button, {ButtonStyle} from "../../components/Button/Button";
import Heading from "../../components/Typography/Heading";
import Text from "../../components/Typography/Text";
import {TableData, TableHeader} from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import WarningModal from "../../components/Modal/WarningModal";


const SupplierList: React.FC = () => {
  const authProvider = useContext(AuthContext)
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [supplierToDelete, setSupplierToDelete] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {
    authProvider.fetchSuppliers()
  }, [])

  useEffect(() => {
    if (supplierToDelete.length > 0) {
      setShowAlertModal(true)
    }
  }, [supplierToDelete])

  useEffect(() => {
    if (!showAlertModal) {
      setSupplierToDelete("")
    }
  }, [showAlertModal])

  const editSupplier = (supplierId:string) => {
    navigate(`/suppliers/${supplierId}`)
  }

  const deleteSupplier = async () => {
    await authProvider.deleteSupplier(supplierToDelete)
    setShowAlertModal(false)
  }

  return (
    <Panel>
      <div className="sm:flex sm:items-center sticky top-0 bg-white pb-4 z-10">
        <div className="sm:flex-grow">
          <Heading>Leveranciers</Heading>
          <Text>Alle Leveranciers</Text>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 space-x-2">
          <Button
            onClick={() => {
              console.log("Adding a supplier!")
              navigate("/suppliers/create")
            }}
            buttonStyle={ButtonStyle.NEW}
          >
            Leverancier toevoegen
          </Button>
        </div>
      </div>

      <div className="mt-8 flex flex-col overflow-hidden">
        <div className="py-2">
          <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
            <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <TableHeader>Leverancier</TableHeader>
                <TableHeader>Plaats</TableHeader>
                <TableHeader><span className="sr-only">Aanpassen</span></TableHeader>
                <TableHeader><span className="sr-only">Verwijderen</span></TableHeader>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {authProvider.suppliers.map((supplier) => (
                <tr key={supplier.id} onDoubleClick={() => editSupplier(supplier.id)} className="hover:backdrop-brightness-95">
                  <TableData>
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={supplier.image} alt=""/>
                      </div>
                      <div className="ml-4">
                        <div className="font-medium">{supplier.name}</div>
                        <div className="text-secondary/80">
                          {supplier.description.substring(0, 25)}{supplier.description.length > 25 && "..."}
                        </div>
                      </div>
                    </div>
                  </TableData>
                  <TableData>{supplier.location_name}</TableData>
                  <TableData classList="text-right text-red-500 hover:text-red-800 cursor-pointer">
                    <TrashIcon
                      onClick={() => setSupplierToDelete(supplier.id)} className="h-6 w-6"
                    />
                    <span className="sr-only">, {supplier.name}</span>
                  </TableData>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <WarningModal
        isOpen={showAlertModal}
        setIsOpen={setShowAlertModal}
        confirmAction={deleteSupplier}
        cancelAction={setShowAlertModal}
        displayText="Weet je zeker dat je deze leverancier wilt verwijderen?"
      />
    </Panel>
  )
}

export default SupplierList;