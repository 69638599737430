import React, {useContext} from 'react';
import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login/Login";
import AuthProvider, {AuthContext} from "./providers/AuthProvider";
import Dashboard from "./pages/Dashboard/Dashboard";
import AppLayout from "./layout/Applayout/AppLayout";
import EditStore from "./pages/Store/EditStore";
import CreateStore from "./pages/Store/CreateStore";
import ProductList from "./pages/Product/ProductList";
import CreateProduct from "./pages/Product/CreateProduct";
import UpdateProduct from "./pages/Product/UpdateProduct";
import SupplierList from "./pages/Supplier/SupplierList";
import CreateSupplier from './pages/Supplier/CreateSupplier';
import UpdateSupplier from './pages/Supplier/UpdateSupplier';


const ProtectedRoute: React.FC = () => {
  const authContext = useContext(AuthContext);
  if(authContext.loaded) {
    return !authContext.user ? <Navigate to="/login"/> : <AppLayout/>;
  }
  return <div/>
};

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route element={<ProtectedRoute/>}>
          <Route path="/" element={<Dashboard/>}/>
          <Route path="/store/create" element={<CreateStore/>}/>
          <Route path="/store/edit" element={<EditStore/>}/>
          <Route path="/products" element={<ProductList/>}/>
          <Route path="/products/create" element={<CreateProduct/>}/>
          <Route path="/products/:productId" element={<UpdateProduct/>}/>
          <Route path="/suppliers" element={<SupplierList/>}/>
          <Route path="/suppliers/create" element={<CreateSupplier/>}/>
          <Route path="/suppliers/:supplierId" element={<UpdateSupplier/>}/>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
