import {Product} from "../plugins/middleware-api-client";

export function calculatePriceIncl(product: Product) {
  return Math.round((product.costPrice / (1 - product.margin / 100) * (1 + product.vat / 100)));
}

export function calculateCostPrice(product: Product) {
  return Math.round((product.priceIncl / (1 + product.vat / 100) * (1 - product.margin / 100)));
}

export function calculateProfitMargin(product: Product) {
  const priceExcl = Math.round(product.priceIncl / (1 + product.vat / 100));
  const costPriceDifference = priceExcl - product.costPrice;
  return Math.round(costPriceDifference / priceExcl * 100);
}