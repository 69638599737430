import React from "react";
import {TextColor} from "./TextStyles";
import {classNames} from "../../utils/classNames";

interface TextProps {
  tag?: keyof JSX.IntrinsicElements
  textColor?: TextColor
  classList?: string
}

const Text:React.FC<TextProps> = ({tag, textColor, classList, children}) => {
  if(!tag) tag = 'p';
  if(!textColor) textColor = TextColor.SECONDARY
  const Tag = tag as keyof JSX.IntrinsicElements;
  return (
    <Tag className={classNames(
      "font-normal",
      textColor,
      classList
    )}>
      {children}
    </Tag>
  )
}

export default Text;