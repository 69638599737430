import Modal, {IModalProps} from "./Modal";
import Button, {ButtonStyle} from "../Button/Button";
import React from "react";


export const ALLOWED_SIZE = 710000

export const allowedFileSize = (fileSize:number) => {
  return fileSize < ALLOWED_SIZE;
}

interface FileSizeWarningProps extends IModalProps{
  fileSize: number;
}


const FileSizeWarningModal:React.FC<FileSizeWarningProps> = ({isOpen, setIsOpen, fileSize}) => {
  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Modal.Body>
          <div className="mb-4">
            <h3 className="text-brand-gray-500 font-bold">
              Afbeelding te groot
            </h3>
            <p>
              Deze afbeelding is {Math.round(fileSize/1000)}KB. <br/>
              Het maximale formaat is {ALLOWED_SIZE/1000}KB.
            </p>
          </div>

          <div className="w-full flex flex-col md:flex-row md:justify-between">
            <Button
              type="button"
              buttonStyle={ButtonStyle.CANCEL}
              onClick={() => setIsOpen(false)}
            >
              Melding sluiten
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FileSizeWarningModal;