import React from "react";
import {classNames} from "../../utils/classNames";

interface DescriptionProps {
  classList?: string
}

export const DescriptionTerm:React.FC<DescriptionProps> = ({classList, children}) => {
  return (
    <dt className={classNames(
      "text-sm font-medium text-secondary", classList
    )}>{children}</dt>
  )
}

export const DescriptionDetails:React.FC<DescriptionProps> = ({classList, children}) => {
  return (
    <dd className={classNames(
      "mt-1 text-sm text-secondary", classList
    )}>{children}</dd>
  )
}

export const DescriptionItem:React.FC<DescriptionProps> = ({classList, children}) => {
  return (
    <div className={classNames(
      "col-span-1", classList
    )}>{children}</div>
  )
}

const DescriptionList:React.FC<DescriptionProps> = ({classList, children}) => {
  return (
    <dl className={classNames(
      "grid gap-x-4 gap-y-4 grid-cols-2", classList
    )}>
      {children}
    </dl>
  )
}

export default DescriptionList