import {initializeApp} from "firebase/app";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import {REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_DOMAIN, REACT_APP_FIREBASE_PROJECT_ID} from "../config";

const config = {
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_DOMAIN,
}

const defaultApp = initializeApp(config);

const auth = getAuth(defaultApp);
auth.setPersistence({
  type: "LOCAL",
})

const functions = getFunctions(defaultApp,"europe-west1");

if (REACT_APP_FIREBASE_DOMAIN === "emulator") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "http://localhost", 5001);
}

const openStoreDoor = httpsCallable(functions, "openStoreDoor");
export {auth, openStoreDoor};
