import React from "react";
import {TextColor} from "./TextStyles";
import {classNames} from "../../utils/classNames";

interface TextProps {
  href: string
  textColor?: TextColor
  classList?: string
}

const A:React.FC<TextProps> = ({href, textColor, classList, children, ...rest}) => {
  return (
    <a
      href={href}
      {...rest}
      className={classNames(
        "text-primary hover:text-primary-600 border-primary hover:border-primary-600 border-b",
        classList,
        textColor
      )}
    >
      {children}
    </a>
  )
}

export default A;