import React, {useEffect, useState} from "react";
import {classNames} from "../../utils/classNames";
import {XIcon} from "@heroicons/react/solid";

interface MultiInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  values: string[];
  inline?: boolean
  label?: string;
  classList?: string;
  updateValues: (values: string[]) => void;
}

const MultiInput: React.FC<MultiInputProps> = ({values, updateValues , inline = true, id, name, type, label, classList, ...rest}) => {
  const [_values, setValues] = useState(values || [])
  const [newValue, setNewValue] = useState("")
  useEffect(() => {
    updateValues(_values)
  }, [_values])
  return (
    <div className={classNames(
      inline ? "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" : "space-y-1",
      classList
    )}>
      {label &&
      <label
          htmlFor={name}
          className={classNames(
            "block text-sm font-medium text-secondary",
            inline ? "sm:mt-px sm:pt-2" : ""
          )}>
        {label}
      </label>
      }
      <div className={classNames(
        "mt-1",
        inline ? "sm:mt-0 col-span-1 sm:col-span-2 md:col-span-1" : ""
      )}>
        <div className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus-within:border-2 focus-within:border-secondary sm:text-sm">
          {_values && _values.map((value, i) => (
            <span
              key={`product_`+i}
              className="border border-secondary py-1 px-2 rounded-md bg-secondary-100/20 m-2 inline-flex items-center"
            >
              <span>{value}</span>
              <XIcon
                className="text-red-500 hover:text-red-700 cursor-pointer w-4 h-4 transition-all"
                onClick={() => {
                  setValues(_values.filter(function(_value) {
                    return _value !== value
                  }));
                }}
              />
            </span>
          ))}

          <input
            id={id}
            name={name}
            type="text"
            {...rest}
            value={newValue}
            onKeyDown={(e) => {
              if(e.key === "Enter") {
                e.preventDefault()
                if(!_values.includes(newValue)) {
                  setValues((f) => ([...f, newValue]))
                }
                setNewValue("")
              }
            }}
            onChange={(e) => {
              setNewValue(e.target.value)
            }}
            className={classNames(
              "border-none focus:outline-0 focus:ring-0 rounded-lg sm:text-sm",
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default MultiInput;