
import React, {
  FormEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../../../providers/AuthProvider";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import Text from "../../../components/Typography/Text";

interface StockDownloadProps {
  open: boolean;
  closeModal: () => void;
  filter: string,
  supplier: string,
  category: string,
}

const StockDownloadModel: React.FC<StockDownloadProps> = (
  props: StockDownloadProps
) => {
  const authContext = useContext(AuthContext);

  const [storeId, setStoreId] = useState<string>("");

  // stores = authContext.stores
  // add a handleSubmit that handles exporting the products
  // this should submit a stockmutation for the store from which the product(s) are written off
  // it should of course also handle the submit to add the stockmutations for the store to which the products are exported
  const handleSubmit = async () => {
  // TODO: download bestellijst for selected store
    authContext.exportProductStockBelowTarget(
        storeId,
        props.filter,
        props.supplier,
        props.category,
    );
    props.closeModal();
  };

  useEffect(() => {
    if (authContext.stores[0]) {
      setStoreId(authContext.stores[0].id);
    }
  }, [authContext.stores]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => props.closeModal()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 md:w-1/2">
              <form
                onSubmit={async (e: FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                className="space-y-8"
              >
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-secondary"
                    >
                    Bestellijst dowloaden
                    </Dialog.Title>
                    <div className="mt-2">
                      <Text classList="text-sm">
                        Kies de winkel waar je je bestellijst van wil dowloaden
                      </Text>
                    </div>
                  </div>
                  <div
                    style={{ maxHeight: "60vh" }}
                    className="overflow-y-scroll"
                  >
                    <label
                      htmlFor="toStore"
                      className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2"
                    >
                    Winkel
                    </label>
                    <select
                      id="toStore"
                      name="toStore"
                      className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => {
                        setStoreId(e.target.value);
                      }}
                    >
                      {authContext.stores.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-between mt-5 sm:mt-6 sticky bottom-0 bg-white">
                  <Button
                    type="button"
                    buttonStyle={ButtonStyle.CANCEL}
                    onClick={() => {
                      setStoreId(authContext.stores[0].id);
                      props.closeModal();
                    }}
                  >
                    Annuleren
                  </Button>
                  <Button
                    buttonStyle={ButtonStyle.SAVE}
                  >
                   Downloaden
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default StockDownloadModel;
