import React from "react";
import {classNames} from "../../utils/classNames";

export interface InputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  inline?: boolean
  label?: string;
  classList?: string;
  required?: boolean
}

const TextArea: React.FC<InputProps> = ({inline = true, id, name, type, label, required = true, classList, ...rest}) => {
  return (
    <div className={classNames(
      inline ? "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" : "space-y-1",
      classList
    )}>
      {label &&
      <label htmlFor={name}
             className={classNames("block text-sm font-medium text-secondary", inline ? "sm:mt-px sm:pt-2" : "")}>
        {label}
      </label>
      }
      <div className={classNames(
        "mt-1",
        inline ? "sm:mt-0 col-span-1 sm:col-span-2 md:col-span-1" : ""
      )}>
        <textarea
          id={id}
          name={name}
          {...rest}
          required={required}
          className="w-full block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
        />
      </div>
    </div>
  )
}

export default TextArea;