import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import {Supplier} from "../../../plugins/middleware-api-client";
import {getBase64} from "../../../utils/getBase64";
import Input from "../../../components/Form/Input";
import {AuthContext} from "../../../providers/AuthProvider";
import TextArea from "../../../components/Form/TextArea";
import FileSizeWarningModal, {allowedFileSize} from "../../../components/Modal/FileSizeWarningModal";
import SupplierUspsForm from "./SupplierUspsForm";

interface SupplierFormProps {
  handleSubmit: (supplier: Supplier) => void
  handleCancel: () => void
  supplierToUpdate?: Supplier | null
}

const SupplierForm:React.FC<SupplierFormProps> = ({handleSubmit, handleCancel, supplierToUpdate}) => {
  const authContext = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false)
  const [supplier, setSupplier] = useState<Supplier>({
    city: "",
    description: "",
    id: "",
    image: "",
    location_name: "",
    name: "",
    street_name: "",
    street_number: "",
    usps: []
  });
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileSizeWarningModal, setFileSizeWarningModal] = useState<boolean>(false);

  useEffect(() => {
    if(supplierToUpdate) {
      setSupplier({
        ...supplierToUpdate,
        usps: supplierToUpdate.usps === null ? [] : supplierToUpdate.usps
      })
    }
    setLoaded(true)
  }, [])

  const updateImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    let file = e.target.files[0]
    if (!allowedFileSize(file.size)) {
      setFileSize(file.size)
      setFileSizeWarningModal(true)
      return false;
    }
    let base64Image = await getBase64(file)
    setSupplier((f) => ({
      ...f,
      image: base64Image
    }))
  }

  return (
    <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(supplier)
    }} >
    <div
    className="relative w-full rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm"
  >
    <div className="flex flex-row items-center flex-between w-full">
      <div className="flex items-center">
        <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100 flex">
          {supplier.image ?
            <img className="self-center" src={supplier.image} alt="Supplier"/>
            :
            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
            </svg>
          }
        </span>
        <label
          htmlFor={`file-upload`}
          className="ml-5 relative cursor-pointer rounded-md font-medium text-secondary-600 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
        >
          <span>Afbeelding uploaden</span>
          <input
            id={`file-upload`}
            name={`file-upload`}
            type="file"
            className="sr-only"
            accept="image/*"
            onChange={(e) => updateImage(e)}
          />
        </label>
      </div>
    </div>
    <Input
      classList="mt-2"
      inline={false}
      label="Naam"
      name={`supplier-name`}
      id={`supplier-name`}
      type="text"
      defaultValue={supplier.name}
      onChange={(e) => {
        setSupplier(f => ({
          ...f,
          name: e.target.value
        }))
      }}
    />
    <Input
      classList="mt-2"
      inline={false}
      label="Naam leverancier"
      name={`supplier-location`}
      id={`supplier-location`}
      type="text"
      defaultValue={supplier.location_name}
      onChange={(e) => {
        setSupplier(f => ({
          ...f,
          location_name: e.target.value
        }))
      }}
    />
    <TextArea
      classList="mt-2"
      inline={false}
      label="Beschrijving"
      id={`supplier-description`}
      name={`supplier-description`}
      type="text"
      defaultValue={supplier.description}
      onChange={(e) => {
        setSupplier(f => ({
          ...f,
          description: e.target.value
        }))
      }}
    />
    <div className="flex mt-2">
      <Input
        classList="w-2/3"
        inline={false}
        label="Straat"
        id={`supplier-street-name`}
        name={`supplier-street-name`}
        type="text"
        defaultValue={supplier.street_name}
        onChange={(e) => {
          setSupplier(f => ({
            ...f,
            street_name: e.target.value
          }))
        }}
      />
      <Input
        classList="w-1/3 pl-4"
        inline={false}
        label="Nummer"
        id={`supplier-street-number`}
        name={`supplier-street-number`}
        type="text"
        defaultValue={supplier.street_number}
        onChange={(e) => {
          setSupplier(f => ({
            ...f,
            street_number: e.target.value
          }))
        }}
      />
    </div>
    <Input
      classList="mt-2"
      inline={false}
      label="Stad"
      id={`supplier-city`}
      name={`supplier-city`}
      type="text"
      defaultValue={supplier.city}
      onChange={(e) => {
        setSupplier(f => ({
          ...f,
          city: e.target.value
        }))
      }}
    />
    <div className="flex justify-between mt-2 content-center">
      <label
        className="block text-sm font-medium text-secondary" >
        Natuurinclusief
      </label>
      <Button type="button" classList="ml-3" onClick={() => {
        setSupplier(f => ({
          ...f,
          usps: [...f.usps, ""]
        }))
      }}>
        +
      </Button>
    </div>
    {supplier.usps.map((usp, uspIndex) => (
      <SupplierUspsForm
        key={`supplier-usp-${uspIndex}`}
        supplierIndex={uspIndex}
        uspIndex={uspIndex}
        supplierUsp={usp}
        updateUsp={ (value: string) =>
          setSupplier(f => {
            supplier.usps[uspIndex] = value
            return f;
          })
        }
        removeUsp={ (uspIndex: number) =>
          setSupplier(f => ({
            ...f,
            usps: f.usps.filter((_, index) => index !== uspIndex)
          }))
        }
      />
    ))}
    <div>
        <div className="flex justify-end">
          <Button type="button" onClick={handleCancel} buttonStyle={ButtonStyle.CANCEL}>
            Annuleren
          </Button>
          <Button classList="ml-3">
            Opslaan
          </Button>
        </div>
      </div>
    <FileSizeWarningModal isOpen={fileSizeWarningModal} setIsOpen={setFileSizeWarningModal} fileSize={fileSize}/>
  </div>
  </form>
)
}

export default SupplierForm;
