import React from 'react';
import {classNames} from "../../utils/classNames";

interface TableItemProps {
  classList?: string
}

export const TableHeader:React.FC<TableItemProps> = ({classList, children}) => {
  return (
    <th
      scope="col"
      className={classNames(
        "py-3.5 px-3 text-left text-sm font-semibold text-secondary sm:pl-6",
        classList
      )}
    >
      {children}
    </th>
  )
}

export const TableData:React.FC<TableItemProps> = ({classList, children}) => {
  return (
    <td
      className={classNames(
        "whitespace-nowrap px-3 first:pl-4 py-4 text-sm text-secondary sm:pl-6",
        classList
      )}
        >
      {children}
    </td>
  )
}