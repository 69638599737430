import React from "react";
import AppHeader from "./AppHeader";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

const AppLayout: React.FC = ({children}) => {
  return (
    <div className="bg-default min-h-screen min-w-screen max-w-screen">
      <AppHeader/>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <div className="h-full flex">
        <div className="flex flex-col flex-1 ">
          <main className="flex-1 relative">
            <Outlet/>
          </main>
        </div>
      </div>
    </div>
  )
}

export default AppLayout;