import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {StoreCreateRequest} from "../../plugins/middleware-api-client";
import StoreForm from "./components/StoreForm";
import {AuthContext} from "../../providers/AuthProvider";
import Panel from "../../components/Panel/Panel";

const EditStore:React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const handleSubmit = (store:StoreCreateRequest) => {
    if(authContext.activeStore?.id){
      authContext.updateStore(authContext.activeStore.id, store)
        .then(res => {
          navigate("/")
        }).catch(err => {
          console.error(err)
      })
    }
    console.log(store)
  }

  return (
    <Panel>
      <StoreForm handleSubmit={handleSubmit} handleCancel={() => navigate('/')} storeToUpdate={authContext.activeStore}/>
    </Panel>
  )
}

export default EditStore;