import React, {useContext} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'
import {AuthContext} from "../../../providers/AuthProvider";
import {NavLink} from "react-router-dom";

interface PaginationProps {
  page: number
  hasNextPage: boolean,
  onPageChange: (page: number) => void
}

const Pagination:React.FC<PaginationProps> = ({page, hasNextPage, onPageChange}) => {
  const authProvider = useContext(AuthContext)
  const limit = 100
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        {page > 1 &&
          <button
            onClick={() => {onPageChange(page - 1)}}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
        }
        {hasNextPage &&
          <button
            onClick={() => {onPageChange(page + 1)}}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        }
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium">{page * limit - limit + 1}</span> tot <span className="font-medium">{page * limit - limit + authProvider.products.length}</span> van de{' '}
            <span className="font-medium">{authProvider.products.length}</span> resultaten
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            {page > 1 &&
              <button
                onClick={() => {onPageChange(page - 1)}}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            }
            <span
              className="z-10 bg-default border-secondary text-secondary-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {page}
            </span>
            { hasNextPage &&
              <button
                onClick={() => {onPageChange(page + 1)}}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            }
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination