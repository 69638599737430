import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {defaultService} from "../plugins/axios";
import {
  ActiveCompanyRequest,
  BulkMutation,
  Product,
  Store,
  StoreCreateRequest,
  StoreProduct,
  Supplier
} from "../plugins/middleware-api-client";
import {auth, openStoreDoor} from "../plugins/firebase";
import {onAuthStateChanged, onIdTokenChanged, signInWithEmailAndPassword, signOut, User} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {AxiosRequestConfig} from "axios";

export interface AuthUser {
  email: string;
  password: string;
}

interface AuthContextProps {
  user: User | null;
  loaded: boolean;
  login: (authUser: AuthUser) => void;
  logout: () => void;
  stores: Store[];
  activeStore: Store | null;
  switchActiveStore: (activeCompanyRequest: ActiveCompanyRequest, notify?: boolean) => Promise<void>
  createStore: (store: StoreCreateRequest) => Promise<boolean>
  updateStore: (id: string, store: StoreCreateRequest) => Promise<boolean>
  products: Product[];
  storeProducts: StoreProduct[];
  fetchProducts: (page?: number, filter?: string, category?: string, supplier?: string, storeId?: string) => void
  fetchProductStock: (productId: string) => Promise<StoreProduct[]>
  fetchMultipleProductStock: (productIds: string[], storeId?: string) => void
  exportProductStockBelowTarget: (storeId: string, filter?: string, category?: string, supplier?: string) => void
  exportProductStock: (filter?: string, supplier?: string, category?: string) => void
  createProduct: (product: Product) => Promise<Product | undefined>
  updateProduct: (productId: string, product: Product) => Promise<boolean>
  updateStoreProducts: (productId: string, storeProducts: StoreProduct[]) => Promise<boolean>
  deleteProduct: (productId: string) => Promise<boolean>
  bulkMutations: (mutations: BulkMutation[]) => Promise<boolean>
  suppliers: Supplier[];
  fetchSuppliers: () => void;
  createSupplier: (supplier: Supplier) => Promise<boolean>
  updateSupplier: (supplierId: string, supplier: Supplier) => Promise<boolean>
  deleteSupplier: (supplierId: string) => Promise<boolean>
  activeStoreId: string
  token: string
  forceOpenStoreDoor: (storeId: string) => Promise<void>
  franchiseId: string | null;
}

export const AuthContext = React.createContext({} as AuthContextProps)

const AuthProvider: React.FC = ({children}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [stores, setStores] = useState<Store[]>([])
  const [activeStore, setActiveStore] = useState<Store | null>(null)
  const [activeStoreId, setActiveStoreId] = useState<string>("")
  const [franchiseId, setFranchiseId] = useState<string>("")
  const [token, setToken] = useState<string>("")
  const [products, setProducts] = useState<Product[]>([])
  const [storeProducts, setStoreProducts] = useState<StoreProduct[]>([])
  const [suppliers, setSuppliers] = useState<Supplier[]>([])

  const getOptions = async (withStore = true) => {
    let options = {} as AxiosRequestConfig
    if (user) {
      const firebaseToken = await user.getIdToken()
      options = {
        headers: {
          'Authorization': `Bearer ${firebaseToken}`,
        }
      }

      if (withStore && options.headers) {
        options.headers["X-Store-Id"] = activeStoreId
      }
      return options
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await user.getIdToken(true).then((token) => {
          setToken(token)
        })
        await user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims["activeStoreId"]) {
            localStorage.setItem('active_company', idTokenResult.claims["activeStoreId"] as string)
            setActiveStoreId(idTokenResult.claims["activeStoreId"] as string)
          }
          if (idTokenResult.claims["franchiseId"]) {
            localStorage.setItem('franchiseId', idTokenResult.claims["franchiseId"] as string)
            setFranchiseId(idTokenResult.claims["franchiseId"] as string)
          }
        })
        setUser(user)
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    })
  }, [])

  useEffect(() => {
    onIdTokenChanged(auth, async (user) => {
      if (user) {
        await user.getIdToken().then((token) => {
          setToken(token)
        })
      }
    });
  }, [])

  useEffect(() => {
    if (loaded && token) {
      fetchStores()
    }
  }, [loaded, token])

  useEffect(() => {
    const storageStoreId = localStorage.getItem('active_company');
    if (storageStoreId && stores.length > 0) {
      const activeStore = getStoreFromId(storageStoreId);
      switchActiveStore({
        activeCompanyId: activeStore.id
      }, false)
    } else if (stores[0]) {
      switchActiveStore({
        activeCompanyId: stores[0].id
      }, false)
    }
  }, [stores]);

  useEffect(() => {
    if (activeStoreId) {
      const store = stores.find((store) => store.id === activeStoreId)
      if (store) {
        setActiveStore(store)
      }
    }
  }, [stores, activeStoreId])

  const login = async (authUser: AuthUser) => {
    try {
      await signInWithEmailAndPassword(auth, authUser.email, authUser.password)
    } catch (e) {
      console.error(e)
      return
    }
  }

  const logout = async () => {
    signOut(auth).then(() => {
      setUser(null)
      setToken("null")
      navigate('/login')
    }).catch(err => {
      console.error(err)
    })
  }

  const getStoreFromId = (storeId: string): Store => {
    return stores.filter((store) => store.id === storeId)[0]
  }

  const createStore = async (store: StoreCreateRequest) => {
    const options = await getOptions()
    const response = await defaultService.createStore(store, options)
    localStorage.setItem('active_company', response.data.id);
    fetchStores();
    return !!response;
  }

  const updateStore = async (id: string, store: StoreCreateRequest) => {
    const options = await getOptions()
    const response = await defaultService.updateStore(id, store, options)
    setActiveStore(response.data)
    return !!response;
  }

  const fetchStores = async () => {
    const options = await getOptions()
    const response = await defaultService.getStores(options).catch(err => console.log(err))
    if (response) {
      setStores(response.data ?? [])
      if (!response.data) {
        navigate('/store/create')
      }
    }
  }

  const fetchProducts = async (page: number = 1, filter: string | undefined = undefined, category: string | undefined = undefined, supplier: string | undefined = undefined, storeId: string | undefined = undefined) => {
    if (!filter || filter.length < 3) {
      filter = undefined
    }
    const options = await getOptions(false)
    const response = await defaultService.getProducts(activeStoreId, page, category, "", supplier, filter, options)
    if (response.data) {
      setProducts(response.data)
      const productIds = response.data.map(product => product.id)
      await fetchMultipleProductStock(productIds, storeId)
    } else {
      setProducts([])
    }
  }

  const fetchProductStock = async (productId: string) => {
    const options = await getOptions();
    const response = await defaultService.getProductStockRecords(productId, options);
    return response.data
  }

  const fetchMultipleProductStock = async (productIds: string[], storeId: string | undefined) => {
    const options = await getOptions()
    const response = await defaultService.getMultipleProductStockRecords(productIds, storeId, options)
    if (response.data) {
      setStoreProducts(response.data)
    } else {
      setStoreProducts([])
    }
  }

  const updateStoreProducts = async (productId: string, storeProducts: StoreProduct[]) => {
    const options = await getOptions()
    const response = await defaultService.updateProductStockRecords(productId, storeProducts, options)
    return response.status === 200
  }

  const exportProductStock = async (filter?: string, category?: string, supplier?: string) => {
    const options = await getOptions(false)
    const response = await defaultService.getProductExport(activeStoreId, category, supplier, filter, options)
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(String(response.data));
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Producten';
    hiddenElement.click();
  }

  const exportProductStockBelowTarget = async (storeId: string, filter?: string, category?: string, supplier?: string) => {
    const options = await getOptions(false)
    const response = await defaultService.getProductStockBelowTargetExport(storeId, category, supplier, filter, options)
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(String(response.data));
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Bestellijst';
    hiddenElement.click();
  }

  const createProduct = async (product: Product, storeproducts?: StoreProduct[]) => {
    const options = await getOptions()
    const response = await defaultService.createProduct(product, options)
    if (response) {
      await fetchProducts(1)
    }
    return response ? response.data : undefined
  }

  const updateProduct = async (productId: string, product: Product) => {
    const options = await getOptions()
    const response = await defaultService.updateProduct(productId, product, options)
    if (response) {
      await fetchProducts(1)
    }
    return !!response
  }

  const deleteProduct = async (productId: string) => {
    const options = await getOptions()
    const response = await defaultService.deleteProduct(productId, options)
    console.log(response)
    if (response) {
      await fetchProducts(1)
    }
    return !!response
  }

  const fetchSuppliers = async () => {
    const options = await getOptions()
    const response = await defaultService.getSuppliers(options)
    if (response.data) {
      setSuppliers(response.data)
    } else {
      setSuppliers([])
    }
  }

  const createSupplier = async (supplier: Supplier) => {
    const options = await getOptions()
    const response = await defaultService.createSupplier(supplier, options)
    if (response) {
      await fetchSuppliers()
    }
    return !!response
  }

  const updateSupplier = async (supplierId: string, supplier: Supplier) => {
    const options = await getOptions()
    const response = await defaultService.updateSupplier(supplierId, supplier, options)
    if (response) {
      await fetchSuppliers()
    }
    return !!response
  }

  const deleteSupplier = async (supplierId: string) => {
    const options = await getOptions()
    const response = await defaultService.deleteSupplier(supplierId, options)
    if (response) {
      await fetchSuppliers()
    }
    return !!response
  }

  const bulkMutations = async (mutations: BulkMutation[]) => {
    const options = await getOptions()
    mutations = mutations.filter(mutation => mutation.quantity && mutation.quantity !== 0);
    const response = await defaultService.createBulkMutations(mutations, options)
    return !!response
  }

  const switchActiveStore = async (activeCompanyRequest: ActiveCompanyRequest, notify: boolean = true) => {
    const options = await getOptions()
    defaultService.updateActiveCompany(activeCompanyRequest, options).then(() => {
      localStorage.setItem("active_company", activeCompanyRequest.activeCompanyId);
      setActiveStoreId(activeCompanyRequest.activeCompanyId)
      if (notify) toast.success("Actieve winkel gewijzigd")
    }).catch(err => {
      console.error(err)
      if (notify) toast.error("Winkel kon niet gewijzigd worden, probeer het later nog eens.")
    })
  }

  const forceOpenStoreDoor = async (storeId: string) => {
    if (!franchiseId) return;
    try {
      const status = await openStoreDoor(JSON.stringify({store: storeId, force: true}));
      if (status.data) {
        toast.success("Deur geopend");
      }
    } catch (e) {
      toast.error("Deur kon niet geopend worden, probeer het later nog eens.")
    }
  }

  return (
    <AuthContext.Provider value={{
      user,
      loaded,
      stores,
      activeStore,
      activeStoreId,
      switchActiveStore,
      login,
      logout,
      createStore,
      updateStore,
      products,
      fetchProducts,
      fetchProductStock,
      fetchMultipleProductStock,
      storeProducts,
      updateStoreProducts,
      exportProductStock,
      exportProductStockBelowTarget,
      createProduct,
      updateProduct,
      deleteProduct,
      suppliers,
      fetchSuppliers,
      createSupplier,
      updateSupplier,
      deleteSupplier,
      bulkMutations,
      token,
      forceOpenStoreDoor,
      franchiseId,
      // fetchCategories
    }}>
      {loaded &&
          <div>{children}</div>
      }
    </AuthContext.Provider>
  )
}

export default AuthProvider;
