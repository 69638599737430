import React, {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import StoreDetails from "./components/StoreDetails";
import {useNavigate} from "react-router-dom";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import Text from "../../components/Typography/Text";
import Heading from "../../components/Typography/Heading";
import Panel from "../../components/Panel/Panel";
import Button, {ButtonStyle} from "../../components/Button/Button";
import {PencilIcon} from "@heroicons/react/outline";
import {LockOpenIcon} from "@heroicons/react/solid";
import SupplierList from "./components/SupplierList";

const Dashboard: React.FC = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()

  return (
    <div>
      {authContext.activeStore &&
        <article>
          <div>
              <MapContainer
                  scrollWheelZoom={false}
                  doubleClickZoom={false}
                  dragging={false}
                  zoomControl={false}
                  center={[parseFloat(authContext.activeStore?.location.latPosition!), parseFloat(authContext.activeStore?.location.lngPosition!)]}
                  zoom={14}
                  className="w-full h-64"
              >
                  <TileLayer
                      attribution=''
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                      position={[parseFloat(authContext.activeStore?.location.latPosition!), parseFloat(authContext.activeStore?.location.lngPosition!)]}>
                  </Marker>
              </MapContainer>
          </div>
          <div
              className="max-w-5xl px-4 lg:px-0 mx-auto -mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5 relative z-[401]">
              <div className="flex">
                  <img
                      className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-contain bg-white border"
                      src={authContext.activeStore.image}
                      alt={authContext.activeStore.name + ` image`}
                  />
              </div>
          </div>

          <Panel>
              <div className="w-full flex flex-col">
                  <div className="block w-full mb-4">
                      <Heading>{authContext.activeStore.name}</Heading>
                      <Text tag="span">{authContext.activeStore.isVisible ? "Actief" : "Inactief"}</Text>
                  </div>
                  <Button
                      classList="absolute top-4 right-4 md:right-6 md:top-6 lg:right-8 lg:top-8 my-0 text-gray hover:text-gray-600"
                      onClick={() => {
                        navigate("/store/edit")
                      }}
                      buttonStyle={ButtonStyle.NEW}
                  >
                      <PencilIcon className="h-4 w-4"/>
                  </Button>
                  <div className="grid grid-cols-12 md:gap-4">
                    {/* Description list */}
                      <div className="col-span-12 md:col-span-10">
                          <StoreDetails/>
                      </div>
                    {/* Team member list */}
                      <div className="col-span-12">
                          <SupplierList suppliers={authContext.activeStore.suppliers}/>
                      </div>
                  </div>
              </div>
          </Panel>

          {authContext.franchiseId &&
          <div className="w-full flex flex-col">
              <div className="px-4 lg:px-0 mx-auto">
                  <Button
                      classList="text-gray hover:text-gray-600 bg-yellow-100"
                      onClick={async () => {
                        await authContext.forceOpenStoreDoor(authContext.activeStoreId)
                      }}
                      buttonStyle={ButtonStyle.WARNING}
                  >
                      <div className="flex items-center">
                          <LockOpenIcon className="h-4 w-4 mr-2" />
                          <span>Open de deur van deze winkel</span>
                      </div>
                  </Button>
              </div>
          </div>
          }

        </article>
      }
    </div>
  )
}

export default Dashboard;
