import React, {useContext, useEffect, useState} from "react";
import SupplierForm from "./components/SupplierForm";
import {AuthContext} from "../../providers/AuthProvider";
import {useNavigate, useParams} from "react-router-dom";
import {Supplier} from "../../plugins/middleware-api-client";
import {defaultService} from "../../plugins/axios";
import Panel from "../../components/Panel/Panel";

const UpdateSupplier:React.FC = () => {
  let { supplierId } = useParams();
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const [supplier, setSupplier] = useState<Supplier | null>(null)

  useEffect(() => {
    if(supplierId) {
      fetchSupplier().then()
    }
  }, [supplierId])

  const fetchSupplier = async () => {
    const response = await defaultService.getSupplier(supplierId!, {
      headers: {
        'Authorization': `Bearer ${authContext.token}`
      }
    })
    setSupplier(response.data)
  }

  const handleSubmit = (supplier:Supplier) => {
      authContext.updateSupplier(supplierId as string, supplier).then(res => {
        navigate("/suppliers")
      }).catch(err => {
        console.error(err)
      })
  }
  return (
    <Panel>
      { (supplier && authContext.activeStore) &&
        <SupplierForm supplierToUpdate={supplier} handleSubmit={handleSubmit} handleCancel={() => navigate("/suppliers")}/>
      }
    </Panel>
  )
}

export default UpdateSupplier;