import React from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom"
import {classNames} from "../../utils/classNames";

export interface NavigationItemProps {
  href: string,
  name: string
}

const NavigationItem: React.FC<NavigationItemProps> = ({href, name}) => {
  let resolved = useResolvedPath(href);
  let active = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link to={href} className={classNames(
      active ? 'text-secondary' : 'text-secondary/50 hover:text-secondary',
      'group flex items-center px-9 py-4 text-sm font-medium'
    )}>
      {name}
    </Link>
  )
}

export default NavigationItem;