import React from "react";
import {classNames} from "../../utils/classNames";
import {TextColor} from "./TextStyles";

export enum Level {
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HEADING_5,
  HEADING_6
}

const HEADING_TYPE_MAPS: Record<Level, string> = {
  [Level.HEADING_1]: "text-2xl md:text-3xl font-extrabold",
  [Level.HEADING_2]: "text-1xl md:text-2xl font-bold",
  [Level.HEADING_3]: "text-xl font-medium",
  [Level.HEADING_4]: "text-lg font-bold leading-5",
  [Level.HEADING_5]: "text-base font-medium",
  [Level.HEADING_6]: "text-sm font-medium",
};


interface HeadingProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  tag?: keyof JSX.IntrinsicElements;
  level?: Level;
  textColor?: TextColor;
  classList?: string
}

const Heading: React.FC<HeadingProps> = ({level, textColor, tag, classList, children, ...rest}) => {
  if (!level) level = Level.HEADING_1;
  if (!tag) tag = "h1";
  if (!textColor) textColor = TextColor.SECONDARY
  const Tag = tag as keyof JSX.IntrinsicElements;
  return (
    <Tag {...rest} className={classNames(
      HEADING_TYPE_MAPS[level],
      textColor,
      classList
    )}>
      {children}
    </Tag>
  )
}

export default Heading;

