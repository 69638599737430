import React, {useContext} from "react";
import {AuthContext} from "../../../providers/AuthProvider";
import DescriptionList, {
  DescriptionDetails,
  DescriptionItem,
  DescriptionTerm
} from "../../../components/DescriptionList/DescriptionList";

const StoreDetails: React.FC = () => {
  const authContext = useContext(AuthContext);
  return (
    <div key="location" className="sm:col-span-1">
      <DescriptionList>
        <DescriptionItem classList={"col-span-2"}>
          <DescriptionTerm>Omschrijving</DescriptionTerm>
          <DescriptionDetails>{authContext.activeStore!.description}</DescriptionDetails>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionTerm>Straat</DescriptionTerm>
          <DescriptionDetails>{authContext.activeStore!.address.streetName} {authContext.activeStore!.address.streetNumber}</DescriptionDetails>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionTerm>Postcode</DescriptionTerm>
          <DescriptionDetails>{authContext.activeStore!.address.postalCode}</DescriptionDetails>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionTerm>Stad</DescriptionTerm>
          <DescriptionDetails>{authContext.activeStore!.address.city}</DescriptionDetails>
        </DescriptionItem>
      </DescriptionList>
    </div>
  )
}

export default StoreDetails;