import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import ProductForm from "./components/ProductForm";
import {Product, StoreProduct} from "../../plugins/middleware-api-client";
import {AuthContext} from "../../providers/AuthProvider";
import Panel from "../../components/Panel/Panel";

const CreateProduct:React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const handleSubmit = async (product:Product, stock: StoreProduct[]) => {
    // console.log(product)
    if(authContext.activeStoreId !== "") {
      // product.storeId = authContext.activeStoreId
      const createdProduct = await authContext.createProduct(product)

      if(createdProduct === undefined) {
        return
      }

      let createdStock = await authContext.fetchProductStock(createdProduct.id)

      if(createdStock === undefined) {
        navigate("/products")
        return
      }

      createdStock = createdStock.map(storeProduct => {
        for(let i = 0; i < stock.length; i++) {
          if(storeProduct.storeId === stock[i].storeId) {
            storeProduct.targetQuantity = stock[i].targetQuantity
            return storeProduct
          }
        }
        return storeProduct
      })

      await authContext.updateStoreProducts(
        createdProduct.id,
        createdStock
      )

      navigate("/products")
    }
  }

  return (
    <Panel>
      {authContext.activeStore &&
        <ProductForm handleSubmit={handleSubmit} handleCancel={() => navigate("/products")}/>
      }
    </Panel>
  )
}

export default CreateProduct;
