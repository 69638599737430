import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import SupplierForm from "./components/SupplierForm";
import {Supplier} from "../../plugins/middleware-api-client";
import {AuthContext} from "../../providers/AuthProvider";
import Panel from "../../components/Panel/Panel";

const CreateSupplier:React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const handleSubmit = (supplier:Supplier) => {
    authContext.createSupplier(supplier).then(res => {
        navigate("/suppliers")
    }).catch(err => {
        console.error(err)
    })
  }

  return (
    <Panel>
      {authContext.activeStore &&
        <SupplierForm handleSubmit={handleSubmit} handleCancel={() => navigate("/suppliers")}/>
      }
    </Panel>
  )
}

export default CreateSupplier;