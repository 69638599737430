import {DefaultApi} from "./middleware-api-client";
import {REACT_APP_MIDDLEWARE_URL} from "../config";
import {getAuth} from "firebase/auth";
import axios, { AxiosInstance } from "axios";

const addInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(async (config) => {
    const token = await getAuth().currentUser?.getIdToken();

    if(!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
}

const axiosInstance = axios.create();
addInterceptors(axiosInstance);

export const defaultService = new DefaultApi({
  accessToken: undefined,
  apiKey: undefined,
  baseOptions: {
    headers: {
      'Content-Type': '*/*'
    }
  },
  basePath: REACT_APP_MIDDLEWARE_URL,
  formDataCtor: undefined,
  password: "",
  username: "",
  isJsonMime(mime: string): boolean {
    return false;
  },
}, REACT_APP_MIDDLEWARE_URL, axiosInstance);